<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加系统管理用户</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add2')">添加登录账号（从长者档案）</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add3')">添加登录账号（从从业人员）</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="用户名" span="8">
          <vxe-input v-model="searchData.loginName" placeholder="请输入用户名" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="状态" span="8">
          <vxe-select v-model="searchData.userStatus" placeholder="请选择状态" style="width:100%;">
            <vxe-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></vxe-option>
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:operation="{ row }">
          <vxe-button v-if="row.status == '1'" type="text" status="primary" @click="enableDisableStatus(false, row)">禁用</vxe-button>
          <vxe-button v-if="row.status == '0'" type="text" status="primary" @click="enableDisableStatus(true, row)">启用</vxe-button>
          <vxe-button type="text" status="primary" @click="changeUserPwd(row)">修改密码</vxe-button>
          <vxe-button type="text" status="primary" @click="goEditPage(row)">修改信息</vxe-button>
          <vxe-button type="text" status="primary" @click="addUserRoles(row)">新增角色</vxe-button>
          <vxe-button type="text" status="primary" @click="removeUserRoles(row)">删除角色</vxe-button>
        </template>
        <template v-slot:roleList="{ row }">
          <span v-for="(item, index) in row.roleList" :key="index" style="padding:5px 5px;">{{ item.roleName }}</span>
        </template>
      </vxe-grid>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        ></vxe-pager>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="visible1" width="40%" :close-on-click-modal="false">
      <el-form :model="form" ref="form" label-width="90px" :rules="rules">
        <el-form-item :label="reasonTitle" prop="reason">
          <el-input type="textarea" v-model="form.reason" autocomplete="off" :rows="2"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align:center;">
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button @click="resetForm('form')">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="visible2" width="30%" :close-on-click-modal="false">
      <el-form :model="form1" ref="form1" label-width="100px" :rules="rules1">
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form1.newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="oldPassword">
          <el-input v-model="form1.oldPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align:center;">
            <el-button type="primary" @click="submitForm1('form1')">确定</el-button>
            <el-button @click="resetForm1('form1')">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="新增用户角色" :visible.sync="visible" width="40%" :close-on-click-modal="false">
      <div>
        <el-table :data="selectRoleList" ref="singleTable" highlight-current-row border size="medium" :height="height">
          <el-table-column label="" width="40" align="center">
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="radio"
                @change.native="getCurrentRow(scope.row)"
                style="color: #fff;padding-left: 10px; margin-right: -25px;"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="roleName" label="角色名称" align="center"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除用户角色" :visible.sync="visible3" width="40%" :close-on-click-modal="false">
      <div>
        <el-table :data="delRoleList" ref="singleTable" highlight-current-row border size="medium" :height="height">
          <el-table-column label="" width="40" align="center">
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="radio2"
                @change.native="getCurrentRow2(scope.row)"
                style="color: #fff;padding-left: 10px; margin-right: -25px;"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="roleName" label="角色名称" align="center"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel2()">取 消</el-button>
        <el-button type="primary" @click="confirm2()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      rules: {
        reason: [{ required: true, message: '请输入原因', trigger: 'blur' }],
      },
      rules1: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
      },
      statusList: [
        {
          id: 1,
          name: '可用',
        },
        {
          id: 0,
          name: '禁用',
        },
      ],
      loading: false,
      seachStatus: false,
      selectRoleList: [],
      delRoleList: [],
      roleList: [],
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 80,
          align: 'center',
        },
        {
          field: 'loginName',
          title: '用户名',
          showOverflow: true,
          align: 'center',
          width: 130,
        },
        {
          field: 'descrption',
          title: '姓名/描述',
          showOverflow: true,
          align: 'center',
          width: 200,
        },
        {
          field: 'statusName',
          title: '状态',
          showOverflow: true,
          align: 'center',
          width: 80,
        },
        {
          field: 'relateTenant',
          title: '数据分区',
          align: 'center',
          width: 100
        },
        {
          field: 'roleList',
          title: '角色',
          align: 'center',
          width: 600,
          slots: {
            default: 'roleList',
          },
        },
        {
          title: '操作',
          width: 450,
          showOverflow: true,
          align: 'center',
          slots: {
            default: 'operation',
          },
        },
      ],
      tableData: [],
      title: '',
      visible1: false,
      active: false,
      userId: '',
      userName: '',
      reasonTitle: '',
      form: {
        reason: '',
      },
      form1: {
        oldPassword: '',
        newPassword: '',
      },
      visible2: false,
      visible: false,
      selectRoleId: '',
      selectRoleId2: '',
      radio: '',
      radio2: '',
      height: 240,
      visible3: false,
    }
  },
  computed: {
    ...mapState(['layouts']),
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['getRoleList', 'getUserPageList', 'changeUserStatus', 'addUserRole', 'userChangePwd', 'deleteRole']),
    initView() {
      this.getLists()
      this.getRoleLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    enableDisableStatus(active, row) {
      this.form.reason = ''
      this.visible1 = true
      this.active = active
      this.userId = row.id
      if (false == active) {
        this.title = '禁用'
        this.reasonTitle = '禁用原因'
      } else {
        this.title = '启用'
        this.reasonTitle = '启用原因'
      }
      this.resetForm('form')
    },
    changeUserPwd(row) {
      this.userName = row.loginName
      this.visible2 = true
      this.resetForm1('form1')
    },
    addUserRoles(row) {
      this.selectRoleId = ''
      this.userId = row.id
      this.radio = ''
      let roleIdList = []
      for (let i = 0; i < row.roleList.length; i++) {
        roleIdList.push(row.roleList[i].roleId)
      }
      this.selectRoleList = this.roleList.filter(function(x, index) {
        return !roleIdList.includes(x.roleId)
      })
      this.visible = true
    },
    removeUserRoles(row) {
       this.selectRoleId2 = ''
       this.radio2 = ''
       this.delRoleList = row.roleList
       this.visible3 = true
    },
    cancel() {
      this.visible = false
    },
    cancel2() {
      this.visible3 = false
    },
    confirm() {
      this.cancel()
      if (this.selectRoleId) {
        this.addUserRole({
          userId: this.userId,
          roleId: this.selectRoleId,
        }).then((res) => {
          if (res.code == 200) {
            this.$XModal.message({
              message: '操作成功',
              status: 'success',
            })
            this.getLists()
          }
        })
      }
    },
    confirm2() {
      this.cancel2()
      if (this.selectRoleId2) {
        this.deleteRole({
          id: this.selectRoleId2,
        }).then((res) => {
          if (res.code == 200) {
            this.$XModal.message({
              message: '删除成功',
              status: 'success',
            })
            this.getLists()
          }
          this.loading = false
        })
      }
    },
    getCurrentRow(row) {
      this.selectRoleId = row.roleId
    },
    getCurrentRow2(row) {
      this.selectRoleId2 = row.id
    },
    goPage(type, itemData) {
      let pageName = 'AddSystemUser';
      if(type == 'add2'){
        pageName = 'AddSystemUser2';
      }
      if(type == 'add3'){
        pageName = 'AddSystemUser3';
      }
      this.$router.push({
        name: pageName,
        params: {
          type: type,
          id: 'add',
        },
        query: {
          item: 'add',
        },
      })
    },
    goEditPage(itemData) {
      let pageName = 'AditSystemUser';
      this.$router.push({
        name: pageName,
        params: {
          type: 'edit',
          id: itemData.id,
        },
        query: {
          item: JSON.stringify(itemData),
        },
      })
    },
    getLists() {
      this.loading = true
      this.getUserPageList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          loginName: this.searchData.loginName,
          userStatus: this.searchData.userStatus,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    getRoleLists() {
      this.loading = true
      this.getRoleList().then((res) => {
        if (res.code == 200) {
          this.roleList = res.data
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.tablePage.currentPage = 1
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changeUserStatus({
            active: this.active,
            userId: this.userId,
            reason: this.form.reason,
          }).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '操作成功',
                status: 'success',
              })
              this.visible1 = false
              this.getLists()
            }
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form1.newPassword != this.form1.oldPassword){
            this.$XModal.message({
                message: '两次输入的密码不相等',
                status: 'error',
              })
          }else{
            this.userChangePwd({
              userName: this.userName,
              oldPassword: "",
              newPassword: this.form1.newPassword,
            }).then((res) => {
              if (res.code == 200) {
                this.$XModal.message({
                  message: '操作成功',
                  status: 'success',
                })
                this.visible2 = false
              }
            })
          }
        }
      })
    },
    resetForm1(formName) {
      this.$refs[formName].resetFields()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.deleteRole({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
